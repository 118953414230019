import { gql } from '@apollo/client';
import AdviceFragment from './Advice';
import { CustomerFragment } from './Customer';
import EnergyDeltaFragment from './EnergyDelta';
import { HouseEnergyFragment } from './HouseEnergy';
import { QuoteFragment } from './Quote';
import SituationFragment from './Situation';

export const CoordinatesFragment = gql`
  fragment Coordinates on Coordinates {
    latitude
    longitude
  }
`;

export const BasicAddressFragment = gql`
  fragment BasicAddress on House {
    address {
      street
      number
      suffix
      zip
      city
    }
  }
`;

export const CompleteAddressFragment = gql`
  ${CoordinatesFragment}
  fragment CompleteAddress on House {
    address {
      street
      number
      suffix
      zip
      city
      municipality
      coordinates {
        ...Coordinates
      }
    }
  }
`;

export const DetailsFragment = gql`
  ${HouseEnergyFragment}
  fragment Details on House {
    area
    constructionYear
    energyLabel
    energy {
      ...HouseEnergy
    }
    residents
    type
    conservatory
    vve
    woz
    stories
    monument
  }
`;

export const EstimateFragment = gql`
  ${EnergyDeltaFragment}
  fragment Estimate on Estimate {
    energyDelta {
      ...EnergyDelta
    }
    investment
    solution
    comfortScore
    paybackTime
    amount
    isInstallable
    feasibility {
      explanationKey
      status
    }
  }
`;

export const EstimatesFragment = gql`
  ${EstimateFragment}
  fragment Estimates on AverageEstimates {
    wallInsulation {
      ...Estimate
    }
    floorInsulation {
      ...Estimate
    }
    crawlspaceInsulation {
      ...Estimate
    }
    innerRoofInsulation {
      ...Estimate
    }
    atticFloorInsulation {
      ...Estimate
    }
    windowGlazing {
      ...Estimate
    }
    windowFrames {
      ...Estimate
    }
    pvSystem {
      ...Estimate
    }
    centralHeatingBoiler {
      ...Estimate
    }
    hybridHeatPump {
      ...Estimate
    }
    electricHeatPump {
      ...Estimate
    }
    airToAirHeatPump {
      ...Estimate
    }
  }
`;

export const WallsFragment = gql`
  fragment Walls on Walls {
    amount
    area
    connectedGarage
  }
`;

export const FloorFragment = gql`
  fragment Floor on Floor {
    area
    material
  }
`;

export const RoofFragment = gql`
  fragment Roof on Roof {
    area
    azimuth
    type
    roofing
    ridgeHeight
    insulatableInnerRoofArea
    insulatableAtticFloorArea
    atticFloorInsulationDepth
  }
`;

export const WindowFragment = gql`
  fragment Window on Window {
    area
    type
    u
    zta
    space
  }
`;

export const CrawlspaceFragment = gql`
  fragment Crawlspace on Crawlspace {
    compartments
    height
  }
`;

export const HeatingFragment = gql`
  fragment Heating on Heating {
    type
    heatEmissionSystems
    installations
    ventilationType
    draught
  }
`;

export const AppliancesFragment = gql`
  fragment Appliances on Appliances {
    ledLighting
    dishwasher
    savingShower
    bathtub
  }
`;

export const HouseSurfacesFragment = gql`
  ${WallsFragment}
  ${FloorFragment}
  ${RoofFragment}
  ${CrawlspaceFragment}
  ${WindowFragment}
  ${HeatingFragment}
  fragment HouseSurfaces on House {
    walls {
      ...Walls
    }
    floor {
      ...Floor
    }
    crawlspace {
      ...Crawlspace
    }
    roof {
      ...Roof
    }
    windows {
      ...Window
    }
    heating {
      ...Heating
    }
  }
`;

export const HouseFragment = gql`
  ${BasicAddressFragment}
  ${DetailsFragment}
  ${QuoteFragment}
  ${EstimatesFragment}
  ${SituationFragment}
  ${AppliancesFragment}
  ${HouseSurfacesFragment}
  ${AdviceFragment}
  fragment House on House {
    id
    ...BasicAddress
    ...Details
    initialQuestionsAnswered
    averageEstimates {
      ...Estimates
    }
    feasibleSolutions
    sentQuotes {
      ...Quote
    }
    situation {
      ...Situation
    }
    advice {
      ...Advice
    }
    appliances {
      ...Appliances
    }
    ...HouseSurfaces
  }
`;

export const HouseWithCustomerFragment = gql`
  ${CustomerFragment}
  ${BasicAddressFragment}
  ${DetailsFragment}
  ${QuoteFragment}
  ${EstimatesFragment}
  ${SituationFragment}
  ${AppliancesFragment}
  ${HouseSurfacesFragment}
  ${AdviceFragment}
  fragment HouseWithCustomer on House {
    id
    customer {
      ...Customer
    }
    ...BasicAddress
    ...Details
    initialQuestionsAnswered
    averageEstimates {
      ...Estimates
    }
    sentQuotes {
      ...Quote
    }
    situation {
      ...Situation
    }
    appliances {
      ...Appliances
    }
    ...HouseSurfaces
    advice {
      ...Advice
    }
  }
`;

export default HouseFragment;
