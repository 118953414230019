import { gql } from '@apollo/client';
import { ConsentFragment } from './Customer';
import { BasicAddressFragment } from './House';
import { UTMFragment } from './UTM';

export const MeLeadFragment = gql`
  fragment MeLead on Lead {
    landingPage
    referrer
    houses {
      id
      ...BasicAddress
      constructionYear
      type
      created
      situation {
        id
        energyPrices {
          gas
          electricity
          discountedElectricity
        }
      }
    }
    utm {
      ...UTM
    }
    consent {
      ...Consent
    }
    intercomHash
  }
`;

export const MeCustomerFragment = gql`
  ${UTMFragment}
  ${BasicAddressFragment}
  ${ConsentFragment}
  fragment MeCustomer on Customer {
    landingPage
    referrer
    houses {
      id
      ...BasicAddress
      constructionYear
      type
      requestedDuties {
        name
        requestedOn
      }
      created
      intake {
        id
        isStarted
      }
      situation {
        id
        energyPrices {
          gas
          electricity
          discountedElectricity
        }
      }
    }
    utm {
      ...UTM
    }
    consent {
      ...Consent
    }
    email
    gender
    firstName
    lastName
    phone
    address {
      zip
      number
      suffix
    }
    viaSamenPartner
    intercomHash
  }
`;

export const MeOperatorFragment = gql`
  fragment MeOperator on Operator {
    firstName
    lastName
    email
  }
`;

export const MePartnerAgentFragment = gql`
  ${ConsentFragment}
  fragment MePartnerAgent on PartnerAgent {
    firstName
    lastName
    email
    phone
    role
    office {
      id
      collectives {
        id
        key
      }
      company {
        id
        name
        mortgageLender
        organization {
          id
          name
        }
      }
    }
    dossiers {
      id
      customerFirstName
      customerLastName
      hasGreenMortgage
    }
    consent {
      ...Consent
    }
    intercomHash
  }
`;
