import { DeepPartial, FormState, FormValueObject } from '@energiebespaarders/hooks/useForm';
import { Box, Flex, Input, Select } from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import React, { useMemo } from 'react';
import { normalizeZipCode } from './normalizers';

type AddressFieldsProps = {
  disabled?: boolean;
  formState: FormState<FormAddress>;
  handleChange: (values: DeepPartial<FormValueObject<FormAddress>>) => void;
  suffixes: string[];
  suffixesLoading: boolean;
  labelColor?: string;
  fontSize?: number;
  addressType?: string;
  inlineButton?: boolean;
};

export type FormAddress = { zip: string; number: number; suffix?: string };

export const AddressFields: React.FC<AddressFieldsProps> = ({
  disabled = false,
  labelColor = 'white',
  fontSize = 6,
  formState,
  handleChange,
  suffixes,
  suffixesLoading,
  addressType,
  inlineButton,
}) => {
  const suffixOptions: DropdownOption<string>[] = useMemo(
    () =>
      suffixes
        .sort((a, b) => a.localeCompare(b, 'en', { numeric: true })) // sort alphanumerically (i.e. A1, A2, A3 instead of A1, A10, A100)
        .map(suffix => ({ value: suffix, label: suffix })),
    [suffixes],
  );

  const labelAddon = useMemo(() => {
    switch (addressType) {
      case 'customer':
        return 'klant';
      case 'adviser':
        return 'adviseur';
      case 'office':
        return 'kantoor';
      default:
        return '';
    }
  }, [addressType]);

  return (
    <Flex flexWrap="wrap" mx="-3px" alignItems="flex-end">
      <Box width={inlineButton ? 1 / 4 : 1 / 3} px="3px">
        <Input
          name="addressZip"
          value={formState.zip.value}
          touched={formState.zip.touched}
          disabled={disabled}
          error={formState.zip.error}
          fontSize={fontSize}
          label={`Postcode ${labelAddon}`}
          labelColor={labelColor}
          onChange={e => {
            handleChange({ zip: normalizeZipCode(e) });
            if (formState.suffix?.value) handleChange({ suffix: '' });
          }}
          placeholder="1234AB"
          type="text"
          autoComplete="postal-code"
        />
      </Box>
      <Box width={inlineButton ? 1 / 4 : 1 / 3} px="3px">
        <Input
          name="addressNumber"
          value={formState.number.value ? formState.number.value.toFixed(0) : ''}
          touched={formState.number.touched}
          error={formState.number.error}
          disabled={disabled}
          fontSize={fontSize}
          label={`Huisnummer ${labelAddon}`}
          labelColor={labelColor}
          maxLength={5}
          onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          onChange={e => {
            const numericString = e.target.value.match(/[0-9]*$/) ? e.target.value : '';
            handleChange({ number: Number(numericString) });
            if (formState.suffix?.value) handleChange({ suffix: '' });
          }}
          placeholder="56"
          type="number"
          autoComplete="street-number"
          inputMode="numeric"
        />
      </Box>
      <Box width={inlineButton ? 1 / 4 : 1 / 3} px="3px">
        <Select<string>
          blurInputOnSelect
          clearable
          disabled={suffixOptions.length === 0 || disabled}
          fontSize={fontSize}
          id={`addressSuffix${labelAddon}`}
          loading={suffixesLoading}
          label={`Toevoeging ${labelAddon}`}
          labelColor={labelColor}
          onChange={option => {
            if (option) handleChange({ suffix: option.value });
            else handleChange({ suffix: '' });
          }}
          options={suffixOptions}
          placeholder=" "
          tabSelectsValue
          value={
            suffixOptions.length === 0
              ? { value: '', label: '' }
              : suffixOptions.find(option => option.value === formState.suffix?.value)
          }
        />
      </Box>
    </Flex>
  );
};

export default AddressFields;
