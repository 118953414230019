import { gql, useMutation } from '@apollo/client';
import useForm, { isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Input } from '@energiebespaarders/symbols';
import { At } from '@energiebespaarders/symbols/icons/solid';
import React, { ChangeEvent, useCallback } from 'react';

export const REQUEST_CUSTOMER_MAGIC_LINK = gql`
  mutation requestMagicLink($email: String!, $path: String!) {
    requestCustomerMagicLink(email: $email, path: $path)
  }
`;

interface RequestMagicLinkFormValues {
  email: string;
  path: string;
}

const RequestMagicLinkForm: React.FC = () => {
  const [requestCustomerMagicLink, { loading, error }] = useMutation(REQUEST_CUSTOMER_MAGIC_LINK);

  const handleSubmit = useCallback(
    (values: RequestMagicLinkFormValues) => {
      void requestCustomerMagicLink({
        variables: values,
      });
    },
    [requestCustomerMagicLink],
  );

  const { formState, handleChange, submitForm } = useForm<RequestMagicLinkFormValues>({
    initialValues: {
      email: '',
      path: 'mijn-offertes',
    },
    handleSubmit,
    validate: (values, errors) => {
      if (isMissing(values.email)) {
        errors.email = 'Ontbreekt';
      }
      return errors;
    },
  });

  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ email: e.target.value }),
    [handleChange],
  );

  const handleChangePath = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ path: e.target.value }),
    [handleChange],
  );

  return (
    <form onSubmit={submitForm}>
      <Box width={1} px={1}>
        <Input
          type="text"
          label="Redirect path"
          addonSide="start"
          data-private="redact"
          value={formState.path.value}
          error={formState.path.error}
          touched={formState.path.touched}
          onChange={handleChangePath}
        />
        <Input
          type="email"
          label="E-mailadres"
          addonSide="start"
          icon={At}
          data-private="redact"
          value={formState.email.value}
          error={formState.email.error}
          touched={formState.email.touched}
          onChange={handleChangeEmail}
          inputMode="email"
          autoComplete="email"
        />
      </Box>
      <Box width={1} px={1}>
        <Button type="submit" fluid loading={loading} error={error?.message} label="Inloggen" />
      </Box>
    </form>
  );
};

export default RequestMagicLinkForm;
