import { Component, ReactNode } from 'react';
import config from '~/config';

const RED = '#d12424'; // no need to import entire theme here just for Error Boundary

type ErrorBoundaryProps = {
  children: ReactNode[] | ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: string;
};

const reload =
  typeof window !== 'undefined' ? () => window.location.reload() : () => console.log('Server-side');

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: `${error.name}: ${error.message}` };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <div style={{ color: RED }}>Er is iets misgegaan.</div>

          {this.state.error && !config.isProduction && (
            <pre style={{ color: RED, fontSize: '0.875rem' }}>{this.state.error}</pre>
          )}

          {/* Not using a Symbols Button here, since that might have been a source of the error */}
          <button onClick={reload}>Klik hier om de pagina te herladen</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
