import { ChangeEvent } from 'react';

export const normalizeZipCode = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;

  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  const onlyLetters = value.replace(/[^A-Za-z]/g, '').toUpperCase();

  if (onlyNums.length === 4 && onlyLetters.length <= 2) {
    return `${onlyNums}${onlyLetters}`;
  }
  return `${onlyNums.slice(0, 4)}${onlyLetters.slice(0, 2)}`;
};

export default normalizeZipCode;
