import { gql } from '@apollo/client';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

export type ABTestEntry = {
  id: string;
  domain: string;
  experiment: string;
  variant: string;
  subvariant: number;
};

type ContextValue = [ABTestEntry[], Dispatch<SetStateAction<ABTestEntry[]>> | null];

const initialValue: ABTestEntry[] = [];
export const AbTestEntriesContext = React.createContext<ContextValue>([initialValue, null]);

export const AbTestEntryProvider: React.FC = ({ children }) => {
  const [abTestEntries, setAbTestEntries] = useState<ABTestEntry[]>(initialValue);
  const contextValue: ContextValue = [abTestEntries, setAbTestEntries];
  return (
    <AbTestEntriesContext.Provider value={contextValue}>{children}</AbTestEntriesContext.Provider>
  );
};

export default function useABTest() {
  const [abTestEntries, setAbTestEntries] = useContext(AbTestEntriesContext);

  const localStorageEntries =
    typeof window !== 'undefined' && window.localStorage?.getItem('abEntries')
      ? (JSON.parse(window.localStorage.getItem('abEntries')!) as ABTestEntry[])
      : initialValue;

  const handleSetAbTestEntries = (entries: ABTestEntry[]) => {
    setAbTestEntries?.(entries);
    // Also store entries in localStorage so we can reinitialize correctly on reload
    entries
      ? window.localStorage.setItem('abEntries', JSON.stringify(entries))
      : window.localStorage.removeItem('abEntries');
  };

  return {
    abTestEntries: localStorageEntries || abTestEntries,
    setAbTestEntries: handleSetAbTestEntries,
  };
}

export const GET_AB_TEST_VARIANT = gql`
  query getABTestEntry($userId: ID!, $domain: String!) {
    getABTestEntry(userId: $userId, domain: $domain) {
      id
      domain
      experiment
      variant
      subvariant
    }
  }
`;
