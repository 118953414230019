import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { isUserlessSession } from '~/utils/isUserlessSession';

type ContextValue = [string, Dispatch<SetStateAction<string>> | null];

export const ActiveHouseIdContext = React.createContext<ContextValue>(['', null]);

export const ActiveHouseIdProvider: React.FC<{ initialActiveHouseId?: string }> = ({
  children,
  initialActiveHouseId,
}) => {
  const [activeHouseId, setActiveHouseId] = useState(initialActiveHouseId || '');
  const contextValue: ContextValue = [activeHouseId, setActiveHouseId];
  return (
    <ActiveHouseIdContext.Provider value={contextValue}>{children}</ActiveHouseIdContext.Provider>
  );
};

export function useActiveHouseId() {
  const [activeHouseId, setActiveHouseId] = useContext(ActiveHouseIdContext);

  const excludeLocalStorage = isUserlessSession();
  const localStorageHouseId =
    !excludeLocalStorage && typeof window !== 'undefined'
      ? window.localStorage?.getItem('activeHouseId') ?? ''
      : '';

  const handleSetActiveHouseId = (id: string) => {
    setActiveHouseId && setActiveHouseId(id);
    // Also store activeHouseId in localStorage so we can reinitialize correctly on reload, see Initialize component
    if (!excludeLocalStorage) window.localStorage.setItem('activeHouseId', id);
  };
  return {
    activeHouseId: localStorageHouseId || activeHouseId,
    setActiveHouseId: handleSetActiveHouseId,
  };
}
