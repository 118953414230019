import { gql, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useFlag } from 'react-unleash-flags';
import config from '~/config';
import { DataLayerEventNames } from '~/types/analytics';
import { sendGA4Event, sendGA4EventVariables } from '~/types/generated/sendGA4Event';
import { pushToDataLayer } from '~/utils/analytics';
import { ansi } from '~/utils/ansiColors';
import { useGAClientId } from './useGAClientId';
import { useMeOptional } from './useMe';

const SEND_GA4_EVENT = gql`
  mutation sendGA4Event(
    $userId: ID!
    $eventName: String!
    $eventParams: String!
    $clientId: String
  ) {
    sendGA4Event(
      userId: $userId
      eventName: $eventName
      eventParams: $eventParams
      clientId: $clientId
    )
  }
`;

export default function useGAEvent(debug = false) {
  const { me } = useMeOptional();
  const stagingGTMEnabled = useFlag('staging-gtm');
  const gaClientId = useGAClientId();

  const [sendEventGA4] = useMutation<sendGA4Event, sendGA4EventVariables>(SEND_GA4_EVENT);

  const myUtm = useMemo(() => {
    if (!me || me.__typename === 'Operator' || me.__typename === 'PartnerAgent' || !me.utm) {
      return {};
    }
    return {
      ...(me.utm.source ? { source: me.utm.source } : {}),
      ...(me.utm.medium ? { medium: me.utm.medium } : {}),
      ...(me.utm.campaign ? { campaign: me.utm.campaign } : {}),
      ...(me.utm.term ? { term: me.utm.term } : {}),
      ...(me.utm.content ? { content: me.utm.content } : {}),
    };
  }, [me]);

  const handleEvent = useCallback(
    async (event: DataLayerEventNames, params: Record<string, any>, includeUtm = true) => {
      let extendedParams = params;
      if (includeUtm) extendedParams = { ...myUtm, ...params }; // Overwrite me.utm params if passed along

      const stringifiedParams = JSON.stringify(extendedParams || {});

      const msg = gaClientId ? 'pushing event to DataLayer' : 'sending event to GA4';
      if (!config.isProduction && debug) {
        console.log(`⛳ staging-gtm feature flag: ${stagingGTMEnabled?.enabled}`);

        if (stagingGTMEnabled?.enabled) {
          console.log(ansi.lightGreen, `📣 ${msg}:`, event, '\nEvent parameters:');
        } else {
          console.log(ansi.magenta, `🧪 (NOT) ${msg}:`, event, '\nEvent parameters:');
        }

        console.table({
          event,
          ...extendedParams,
          user_id: params.user_id || me?.id,
          user_type: params.user_type || me?.__typename,
          client_id: gaClientId,
        });
      }

      if (config.isDeveloping) return;

      try {
        // NOTE: if clientId is defined, that means the user has a _ga cookie and we can use the GTM dataLayer instead of bypassing it with our API and Measurement Protocol.
        // This is preferred, since GTM can then use the parameters to do other things. Through MP it would go directly to GA4.
        if (gaClientId) {
          pushToDataLayer({ user_id: me?.id, user_type: me?.__typename, event, ...extendedParams });
        } else {
          // Else we use the Measurement Protocol in our back-end to send the event directly to GA4. This means it doesn't hit the GTM dataLayer and can't trigger any GTM tags.
          await sendEventGA4({
            variables: {
              userId: me?.id || '',
              eventName: event,
              eventParams: stringifiedParams,
              clientId: gaClientId, // our GA4 client will use the userId if clientId is undefined or an empty string
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [debug, gaClientId, me?.__typename, me?.id, myUtm, sendEventGA4, stagingGTMEnabled?.enabled],
  );
  return handleEvent;
}
