import { gql } from '@apollo/client';
import FileFragment from './File';

export const GeneralProductFragment = gql`
  fragment GeneralProduct on Product {
    id
    title
    description
    solution
    category
    priceUnit
    tax
    isdeCode
    isPresentedOnQuote
    youTubeId
  }
`;

export const PvPanelFragment = gql`
  ${GeneralProductFragment}
  ${FileFragment}
  fragment PvPanel on PvPanel {
    ...GeneralProduct
    brand {
      id
      name
    }
    # countryOfOrigin
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # series
    # model
    # panelType
    # cellType
    # cellColor
    # backsheetColor
    # frameColor
    # releaseDate
    # perc
    pMax
    # warrantedPMax
    # moduleEfficiency
    # height
    # width
    # weight
    # maxOutputVoltage
    files {
      ...File
    }
  }
`;

export const InverterFragment = gql`
  ${GeneralProductFragment}
  ${FileFragment}
  fragment Inverter on Inverter {
    ...GeneralProduct
    brand {
      id
      name
    }
    # countryOfOrigin
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # height
    # color
    # noise
    # extraWarrantyAvailable
    # phases
    # transformer
    # connectionType
    # width
    # depth
    # weight
    # minDcInput
    # maxDcInput
    # monitoring
    # dcConnectors
    # strings
    # trackers
    files {
      ...File
    }
  }
`;

export const OptimizerFragment = gql`
  ${GeneralProductFragment}
  ${FileFragment}
  fragment Optimizer on Optimizer {
    ...GeneralProduct
    brand {
      id
      name
    }
    # countryOfOrigin
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # maxDcInput
    # height
    # width
    # depth
    # weight
    files {
      ...File
    }
  }
`;

export const InstallationMaterialFragment = gql`
  ${GeneralProductFragment}
  ${FileFragment}
  fragment InstallationMaterial on InstallationMaterial {
    ...GeneralProduct
    materials
    files {
      ...File
    }
  }
`;

export const CompletePvSystemFragment = gql`
  ${PvPanelFragment}
  ${InverterFragment}
  ${OptimizerFragment}
  ${InstallationMaterialFragment}
  fragment CompletePvSystem on CompletePvSystem {
    panelAmount
    pMax
    panelId
    inverterId
    optimizerId
    installationMaterialId
    laborId
    pvPanel {
      ...PvPanel
    }
    inverter {
      ...Inverter
    }
    optimizer {
      ...Optimizer
    }
    installationMaterial {
      ...InstallationMaterial
    }
    labor {
      id
    }
  }
`;

export const WallCavityInsulationFragment = gql`
  fragment WallCavityInsulation on WallCavityInsulation {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    # unitsPerHour
    # rd
    # thickness
  }
`;

export const FloorInsulationFragment = gql`
  fragment FloorInsulation on FloorInsulation {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    # unitsPerHour
    # rd
    # thickness
  }
`;

export const RoofInsulationFragment = gql`
  fragment RoofInsulation on RoofInsulation {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    # unitsPerHour
    # rd
    # thickness
  }
`;

export const GlazingFragment = gql`
  fragment Glazing on Glazing {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # unitsPerHour
    # glassType
    # thickness
    # u
    # zta
  }
`;

export const WindowFrameFragment = gql`
  fragment WindowFrame on WindowFrame {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # unitsPerHour
    # material
  }
`;

export const HeatPumpFragment = gql`
  fragment HeatPump on HeatPump {
    brand {
      id
      name
    }
    productWarranty
    advantages
    disadvantages
    # countryOfOrigin
    # series
    # model
    # releaseDate
    heatPumpType
    # splitUnit
    # mainsConnection
    # maxOperatingCurrent
    # maxElectricityConsumption
    # necessaryFuses
    # modular
    # height
    # width
    # depth
    # weight
    # heatingOutput035
    # electricityConsumption035
    # electricityConsumption055
    # copDelta5
    # copDelta10
    # minFlowTemperature
    # maxFlowTemperature
    # flowRate
    # integratedBoiler
    # separateBoiler
    # boilerCapacity
    # externalUnitNoise
    # internalUnitNoise
    # boilerConnection
    # thermostatConnection
    subsidy
  }
`;

export const CentralHeatingBoilerFragment = gql`
  fragment CentralHeatingBoiler on CentralHeatingBoiler {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # releaseDate
    # width
    # height
    # depth
    # weight
    # cwClass
    # combiBoiler
    # noise
    # efficiency
    # energyLabel
    # openThermSupport
  }
`;

export const BoilerFragment = gql`
  fragment Boiler on Boiler {
    brand {
      id
      name
    }
    advantages
    disadvantages
    productWarranty
    # height
    # weight
    # width
    # depth
  }
`;

export const ThermostatFragment = gql`
  fragment Thermostat on Thermostat {
    brand {
      id
      name
    }
    advantages
    disadvantages
    productWarranty
    # series
    # model
    # wireless
    # programmable
    # smartphoneApp
    # communicationProtocol
    # height
    # width
    # depth
  }
`;

export const FuseboxFragment = gql`
  fragment Fusebox on Fusebox {
    brand {
      id
      name
    }
    # phases
    # groups
    # residualCurrentDevices

    # height
    # width
    # depth
  }
`;

export const HeatingSystemFragment = gql`
  fragment HeatingSystem on HeatingSystem {
    brand {
      id
      name
    }
    # series
    # model
    advantages
    disadvantages
    productWarranty
    # unitsPerHour
    # height
    # width
    # depth
    # weight
    # heatingSystemType
    # power
  }
`;

export const MiscellaneousMainFragment = gql`
  fragment MiscellaneousMain on MiscellaneousMain {
    advantages
    disadvantages
    brand {
      id
      name
    }
    productWarranty
    #height
    #width
    #depth
    #weight
    #material
  }
`;

// export const LaborFragment = gql`
//   fragment Labor on Labor {
//     advantages
//     disadvantages
//   }
// `;

// export const ServiceFragment = gql`
//   fragment Service on Service {
//     advantages
//     disadvantages
//   }
// `;

// export const OtherFragment = gql`
//   fragment Other on Other {
//     advantages
//     disadvantages
//   }
// `;

export const ProductFragment = gql`
  ${GeneralProductFragment}
  ${CompletePvSystemFragment}
  ${PvPanelFragment}
  ${InverterFragment}
  ${OptimizerFragment}
  ${InstallationMaterialFragment}
  ${WallCavityInsulationFragment}
  ${FloorInsulationFragment}
  ${BoilerFragment}
  ${RoofInsulationFragment}
  ${GlazingFragment}
  ${WindowFrameFragment}
  ${CentralHeatingBoilerFragment}
  ${ThermostatFragment}
  ${FuseboxFragment}
  ${HeatPumpFragment}
  ${HeatingSystemFragment}
  ${MiscellaneousMainFragment}
  fragment Product on Product {
    ...GeneralProduct
    ... on CompletePvSystem {
      ...CompletePvSystem
    }
    ... on PvPanel {
      ...PvPanel
    }
    ... on Inverter {
      ...Inverter
    }
    ... on Optimizer {
      ...Optimizer
    }
    ... on InstallationMaterial {
      ...InstallationMaterial
    }
    ... on WallCavityInsulation {
      ...WallCavityInsulation
    }
    ... on FloorInsulation {
      ...FloorInsulation
    }
    ... on RoofInsulation {
      ...RoofInsulation
    }
    ... on RoofFinish {
      paintable
    }
    ... on Glazing {
      ...Glazing
    }
    ... on WindowFrame {
      ...WindowFrame
    }
    ... on CentralHeatingBoiler {
      ...CentralHeatingBoiler
    }
    ... on Boiler {
      ...Boiler
    }
    ... on Thermostat {
      ...Thermostat
    }
    ... on Fusebox {
      ...Fusebox
    }
    ... on HeatPump {
      ...HeatPump
    }
    ... on HeatingSystem {
      ...HeatingSystem
    }
    ... on MiscellaneousMain {
      ...MiscellaneousMain
    }
  }
`;

export default ProductFragment;
