import { Toast } from '@energiebespaarders/symbols';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import React from 'react';
import styled from 'styled-components';
import { IToast } from '../hooks/useToaster';

const StyledToaster = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: ${x => x.theme.z.toaster};
  pointer-events: none;

  ${mediaMin.md} {
    width: auto;
    top: 78px;
    right: 60px;
    left: auto;
  }
`;

const ToastWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;

  ${mediaMin.md} {
    align-items: start;
  }
`;

interface ToasterProps {
  toasts: IToast[];
  removeToast: (uid: number) => void;
}

const Toaster: React.FC<ToasterProps> = ({ toasts, removeToast }) => {
  return (
    <StyledToaster>
      <ToastWrapper>
        {toasts.map(toast => (
          <Toast key={`toast-${toast.uid}`} handleClose={() => removeToast(toast.uid)} {...toast} />
        ))}
      </ToastWrapper>
    </StyledToaster>
  );
};

export default Toaster;
