import { useMutation } from '@apollo/client';
import { Box, Button, Container, Flex, Icon, Wrapper } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import { Alert, Reply } from '@energiebespaarders/symbols/icons/solid';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useClearSession } from '~/hooks/useClearSession';
import { useMeOptional } from '~/hooks/useMe';
import { LOGOUT_CUSTOMER } from '~/queries/customer';
import { LOGOUT_OPERATOR } from '~/queries/operator';
import { LOGOUT_PARTNER_AGENT } from '~/queries/samen';
import { logoutCustomer } from '~/types/generated/logoutCustomer';
import { logoutOperator } from '~/types/generated/logoutOperator';
import { logoutPartnerAgent } from '~/types/generated/logoutPartnerAgent';

interface NotLeadProps {
  isCustomer: boolean;
  isOperator: boolean;
  isPartnerAgent: boolean;
}

const NotLead: React.FC<NotLeadProps> = ({ isCustomer, isOperator, isPartnerAgent }) => {
  const router = useRouter();

  const me = useMeOptional();

  const { clearSession } = useClearSession();

  const [logoutCustomerMutation] = useMutation<logoutCustomer>(LOGOUT_CUSTOMER);
  const [logoutPartnerAgentMutation] = useMutation<logoutPartnerAgent>(LOGOUT_PARTNER_AGENT);
  const [logoutOperatorMutation] = useMutation<logoutOperator>(LOGOUT_OPERATOR);

  const handleLogout = useCallback(async () => {
    const logoutFunc = isPartnerAgent
      ? logoutPartnerAgentMutation
      : isCustomer
      ? logoutCustomerMutation
      : logoutOperatorMutation;

    try {
      await logoutFunc();
    } catch (e) {
      console.error('Could not log out user', e);
    }

    void clearSession(router.query.from ?? router.asPath);
  }, [
    clearSession,
    isCustomer,
    isPartnerAgent,
    logoutCustomerMutation,
    logoutOperatorMutation,
    logoutPartnerAgentMutation,
    router,
  ]);

  useEffect(() => {
    if (!router.isReady || !me) return;

    if (!isCustomer && !isOperator && !isPartnerAgent) {
      if (router.query.from) {
        void router.push(router.query.from as string);
      } else {
        void router.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, me]);

  return (
    <Wrapper forceFullHeight>
      <Container size="md">
        <Box textAlign="center" pt={5}>
          <Box>
            <Icon icon={Alert} solid fill="green" width="4em" />
          </Box>
          <Box py={5}>
            {isCustomer && (
              <>
                <Heading heading="h1" letterSpacing="-0.025em">
                  Je bent momenteel ingelogd als klant
                </Heading>
                <p>Om de gewenste pagina te bekijken moet je eerst uitloggen.</p>
              </>
            )}
            {isPartnerAgent && (
              <>
                <Heading heading="h1" letterSpacing="-0.025em">
                  Je bent momenteel ingelogd als partner
                </Heading>
                <p>Om de webpagina's voor klanten te bekijken moet je eerst uitloggen.</p>
              </>
            )}
            {isOperator && (
              <>
                <Heading heading="h1" letterSpacing="-0.025em">
                  Je bent momenteel ingelogd als beheerder
                </Heading>
                <p>
                  Om de webpagina's voor klanten en partners te bekijken moet je eerst uitloggen.
                </p>
              </>
            )}
          </Box>
        </Box>
        <Flex flexWrap="wrap" mx={-1}>
          <Box width={[1, 1 / 2]} px={1}>
            <Button bgColor="red" inverse fluid onClick={handleLogout}>
              Uitloggen en pagina bekijken
            </Button>
          </Box>
          <Box width={[1, 1 / 2]} px={1}>
            <Button
              iconStart={Reply}
              fluid
              to={
                isPartnerAgent
                  ? '/samen/start'
                  : isCustomer
                  ? '/'
                  : '/operator-control-panel/overview'
              }
            >
              Terug naar{' '}
              {isPartnerAgent ? 'het partnerportaal' : isCustomer ? 'de homepage' : 'het dashboard'}
            </Button>
          </Box>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default NotLead;
