import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { init as SentryInit, configureScope, getCurrentHub } from '@sentry/nextjs';
import dayjs from 'dayjs';
import { isEqual as lodashIsEqual } from 'lodash';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { NextRouter } from 'next/router';
import { DataAttributes } from 'react-use-intercom';
import { Me } from '~/hooks/useMe';
import { getPathnameFromAsPath } from '~/hooks/usePathname';
import { me_me, me_me_Customer, me_me_Lead, me_me_PartnerAgent } from '~/types/generated/me';
import { updateLead as updateLeadType, updateLeadVariables } from '~/types/generated/updateLead';
import { LeadInput } from '~/types/graphql-global-types';
import { pushToDataLayer } from '~/utils/analytics';
import { formatPhoneE164 } from '~/utils/formatPhoneNumber';
import { Writable } from 'src/typeHelpers';
import config from 'src/config';
import { isUtmEmpty, parseUtm } from './utmUtils';

/** Determines most likely active house ID based on most recent order or most recent created date */
export function getActiveHouseId(me: me_me) {
  if (!me) return '';
  if (
    me.__typename === 'InstallerAgent' ||
    me.__typename === 'Operator' ||
    me.__typename === 'PartnerAgent'
  ) {
    return '';
  }

  let activeHouseId = '';

  if (me.houses?.length) {
    const localStorageHouseId = window?.localStorage.getItem('activeHouseId') || '';
    const localHouseIsMine = (me as me_me_Customer).houses.some(
      house => house.id === localStorageHouseId,
    );
    if (localStorageHouseId && localHouseIsMine) {
      // If the customer has an activeHouseId in their localStorage that matches a house they own, use that on load
      activeHouseId = localStorageHouseId;
    } else {
      // Check if there are any requests
      const housesWithRequests =
        (me as me_me_Customer).houses.filter(house => house?.requestedDuties?.length) || [];
      if (housesWithRequests.length > 1) {
        // Grab the house with the most recent request
        let houseWithMostRecentRequest: any;
        let mostRecentRequest: any;

        housesWithRequests.forEach(house => {
          house.requestedDuties.forEach(rs => {
            if (!mostRecentRequest || dayjs(rs.requestedOn).isBefore(dayjs(mostRecentRequest))) {
              mostRecentRequest = rs.requestedOn;
              houseWithMostRecentRequest = house;
            }
          });
        });
        activeHouseId = houseWithMostRecentRequest.id;
      } else if (housesWithRequests.length) {
        // Grab the only house with a request
        activeHouseId = housesWithRequests[0].id;
      } else if (me.houses.length > 1) {
        // If there are no requests at all, but multiple houses, grab the most recent house
        activeHouseId = [...me.houses].sort((a, b) => b.created - a.created)[0].id;
      } else {
        // Grab the only house available
        activeHouseId = me.houses[0].id;
      }
    }
  }

  return activeHouseId;
}

/** LOGROCKET */

type LogRocketUserData = {
  id: string;
  name?: string;
  email?: string;
  zip?: string;
  number?: number;
  suffix?: string;
  street?: string;
  city?: string;
  utm?: string; // UTM source
  landingPage?: string;
  houseId?: string;
};

function getLogRocketUserData(me: me_me): LogRocketUserData {
  const attrs = { id: me.id } as LogRocketUserData;
  if (me.__typename === 'InstallerAgent') return attrs;

  const leadEmail = window?.localStorage.getItem('resultsEmail');
  if (leadEmail) attrs.email = leadEmail;

  if (me.__typename !== 'Lead') {
    attrs.name = `${me.firstName} ${me.lastName}`;
    attrs.email = me.email;
  }

  if (me.__typename === 'Lead' || me.__typename === 'Customer') {
    attrs.utm = me.utm?.source || undefined;
    attrs.landingPage = me.landingPage;
    attrs.houseId = me.houses[0]?.id;
    attrs.zip = me.houses[0]?.address.zip;
    attrs.number = me.houses[0]?.address.number;
    attrs.suffix = me.houses[0]?.address.suffix || '';
    attrs.street = me.houses[0]?.address.street;
    attrs.city = me.houses[0]?.address.city;
  }

  return attrs;
}

function identifyLogRocket(me: me_me, houseId: string) {
  if (me.__typename === 'InstallerAgent') return;

  // Only identify LR session if we initialized it: not all sessions are being recorded due to the session limit
  if (!localStorage.getItem('_lr_id_')) return;

  const attrs = getLogRocketUserData(me);

  // Overwrite houseId if given, default uses first house in `me.houses` array
  if (houseId) attrs.houseId = houseId;

  LogRocket.identify(me.id, attrs);
}

/** SENTRY */

type SentryUserData = {
  id: string;
  email?: string;
  username?: string;
};

type SentryExtraData = {
  UserType?: string;
  LandingPage?: string;
  UTM?: string;
  Pageviews?: string;
  Authenticated?: boolean;
  houseId?: string;
  partnerAgentId?: string;
};

function getSentryUserData(me: me_me): SentryUserData {
  const attrs = { id: me.id } as SentryUserData;
  if (me.__typename === 'InstallerAgent') return attrs;

  const leadEmail = window?.localStorage.getItem('resultsEmail');
  if (leadEmail) attrs.email = leadEmail;

  if (me.__typename !== 'Lead') {
    attrs.email = me.email;
    attrs.username = `${me.firstName} ${me.lastName}`;
  }

  return attrs;
}

function getSentryExtraData(me: me_me): SentryExtraData {
  const attrs = {} as SentryExtraData;
  if (me.__typename === 'InstallerAgent') return attrs;

  attrs.UserType = me.__typename;

  if (me.__typename === 'Lead' || me.__typename === 'Customer') {
    attrs.LandingPage = me.landingPage;
    attrs.UTM = JSON.stringify(me.utm);
    attrs.houseId = me.houses[0]?.id;
  }

  if (me.__typename !== 'Lead') {
    attrs.Authenticated = true;
  }

  if (window?.localStorage.getItem('pageViews')) {
    attrs.Pageviews = window?.localStorage.getItem('pageViews') || undefined;
  }

  return attrs;
}

function identifySentry(me: me_me, houseId: string) {
  if (me.__typename === 'InstallerAgent') return;

  configureScope(scope => {
    const userAttrs = getSentryUserData(me);
    const extraAttrs = getSentryExtraData(me);

    Object.keys(userAttrs).forEach(key =>
      scope.setUser({ key: userAttrs[key as keyof SentryUserData] }),
    );

    // Custom fields use a different method `setExtra`
    Object.keys(extraAttrs).forEach(key =>
      scope.setExtra(key, extraAttrs[key as keyof SentryExtraData]),
    );

    // Overwrite houseId if given, default uses first house in `me.houses` array
    if (houseId) scope.setExtra('houseId', houseId);
  });

  // Add the LogRocket session URL if available
  LogRocket.getSessionURL(url => {
    configureScope(scope => scope.setExtra('sessionURL', url));
  });
}

/** INTERCOM */

export function getIntercomUserData(me: me_me): Partial<DataAttributes> {
  const attrs = {} as Partial<DataAttributes>;
  // We don't want Users in Intercom for Leads or Operators and InstallerAgents can't login here
  if (
    me.__typename === 'InstallerAgent' ||
    me.__typename === 'Lead' ||
    me.__typename === 'Operator'
  ) {
    return attrs;
  }

  attrs.userId = me.id;
  attrs.name = `${me.firstName} ${me.lastName}`;
  attrs.email = me.email;
  attrs.userHash = me.intercomHash;
  attrs.phone = formatPhoneE164(me.phone);
  attrs.createdAt = dayjs(me.created).unix();

  if (me.__typename === 'Customer') {
    attrs.utmSource = me.utm?.source || undefined;
    attrs.utmMedium = me.utm?.medium || undefined;
    attrs.utmCampaign = me.utm?.campaign || undefined;
    attrs.utmContent = me.utm?.content || undefined;
    attrs.utmTerm = me.utm?.term || undefined;
    attrs.customAttributes = { landingPage: me.landingPage, houseId: me.houses[0]?.id };
  }

  if (me.__typename === 'PartnerAgent') {
    attrs.company = {
      companyId: me.office.company.id,
      name: me.office.company.name,
    };
  }

  return attrs;
}

/**
 * Determine if there are LogRocket cookies saved to see if there is a LogRocket session connected to the client
 */
function isLogRocketRunning(cookies: { [x: string]: any }): boolean {
  return !!Object.keys(cookies).find(key => key.includes(config.keys.logrocket.appId));
}

async function initializeLogRocket() {
  LogRocket.init(config.keys.logrocket.appId, {
    release: config.version,
    network: {
      isEnabled: true,
      requestSanitizer: (req: any) => {
        // Replace any passwords with an empty string
        if (req?.body && typeof req.body === 'string') {
          const pwPattern = /(,?"password":")(.*?)"/g;
          const newPasswordPattern = /(,?"newPassword":")(.*?)"/g;
          req.body = req.body.replace(pwPattern, '');
          req.body = req.body.replace(newPasswordPattern, '');
        }
        if (req?.headers['server-side-session-id']) {
          req.headers['server-side-session-id'] = undefined;
        }
        return req;
      },
    },
  });
  setupLogRocketReact(LogRocket);
}

/**
 * Determine if Sentry is running; https://github.com/getsentry/sentry-go/issues/9
 */
function isSentryRunning(): boolean {
  return !!getCurrentHub().getClient();
}

async function initializeSentry() {
  // TODO: Sentry can be initialized in client & server through the next.config.js set-up
  SentryInit({
    dsn: config.keys.sentry.dsn,
    release: config.version,
    environment: config.keys.sentry.environment,
    ignoreErrors: [/Loading chunk [\d]+ failed/],
  });
}

export function initializeThirdPartyServices(
  me: me_me_Lead | me_me_Customer | me_me_PartnerAgent,
  cookies: { [x: string]: any },
  pathname: string,
) {
  const houseId = me.__typename === 'PartnerAgent' ? '' : getActiveHouseId(me);

  if (config.isProduction) {
    if (!isSentryRunning()) {
      void initializeSentry().then(() => identifySentry(me, houseId));
    }

    if (
      !isLogRocketRunning(cookies) &&
      // Due to a influx of sessions on mkt pages, we hit our LR session limit much earlier. Skipping those for now
      !['/blog', '/faq', '/campagne', '/woning-verduurzamen'].some(route =>
        pathname.startsWith(route),
      )
    ) {
      void initializeLogRocket().then(() => identifyLogRocket(me, houseId));
    }
  }
}

/** Passes user info to GTM, LogRocket and Sentry */
export function identifyMe(me?: me_me, houseId?: string) {
  if (!me || me.__typename === 'InstallerAgent') return;

  let _houseId = houseId || '';
  if (!houseId && me.__typename !== 'PartnerAgent' && me.__typename !== 'Operator') {
    _houseId = getActiveHouseId(me);
  }

  const dataLayerVariables: Record<string, any> = { user_id: me.id, user_type: me.__typename };

  if (config.isProduction) {
    identifySentry(me, _houseId);
    identifyLogRocket(me, _houseId);
  }

  if (me.__typename === 'Customer' || me.__typename === 'PartnerAgent') {
    dataLayerVariables.phone = formatPhoneE164(me.phone);
    dataLayerVariables.email = me.email;
    dataLayerVariables.name = `${me.firstName} ${me.lastName}`;
  }

  if (me.__typename === 'Lead' || me.__typename === 'Customer') {
    dataLayerVariables.house_id = _houseId;
    dataLayerVariables.utm = me.utm;
  }

  pushToDataLayer(dataLayerVariables);
}

/**
 * Initializes the app for a new session (after refreshing or logging out)
 *
 * RULES OF THE GAME (Carsten & Leo - 22/01/19)
 * 1. landingPage determines funnel/route and conditional content (i.e. partners, promos, campaigns)
 * 2. UTM campaign determines discount (temporary solution until we have a discount service)
 * 3. Always update landingPage for Lead // NOTE: Changed this to only when there's a UTM (Leo - 26/07/21)
 * 4. Always update UTM for Lead when landing with UTM in URL
 * 5. Only generate UTM for Lead when landing on dedicated, predefined landing pages without UTM in URL (for GA purposes only)
 * 6. Never update landingPage for Customer, no matter what
 * 7. Never update UTM for Customer, no matter what
 */
export async function initializeSessionData(
  me: me_me_Lead | me_me_Customer,
  updateLead: (
    options?: MutationFunctionOptions<updateLeadType, updateLeadVariables>,
  ) => Promise<FetchResult<updateLeadType>>,
  setMe: (newMe?: Me) => void,
  router: NextRouter,
) {
  const { id, utm: userUtm } = me;

  const { asPath: pathname } = router;
  const { urlUtm, queryWithoutUtm } = parseUtm(router.query);

  if (me.__typename === 'Lead') {
    // Mutable copy of `me` to use as leadInput variable
    const documentReferrer = typeof document !== 'undefined' ? document.referrer : '';
    const newMe: Writable<LeadInput> = {
      id: me.id,
      utm: me.utm,
      consent: [...me.consent],
      landingPage: me.landingPage,
      referrer: me.referrer || documentReferrer,
    };
    // Update my landing page if I don't have one yet, or if I am on a page with URL in UTM, indicating new session
    if (!me.landingPage || !isUtmEmpty(urlUtm)) newMe.landingPage = pathname;
    // Update my referrer if I don't have one yet, or if I am on a page with URL in UTM, indicating new session
    if (!me.referrer || (!isUtmEmpty(urlUtm) && !documentReferrer.includes(location.pathname))) {
      newMe.referrer = documentReferrer;
    }
    // Update my UTM if there is one UTM in the URL and it's different from my previous one, indicating new session
    if (!isUtmEmpty(urlUtm) && !lodashIsEqual(userUtm, urlUtm)) {
      newMe.utm = { ...me.utm!, ...urlUtm };
    }
    // Update with mutation and update global state
    void updateLead({ variables: { id, lead: newMe } });
    setMe({ ...me, ...newMe } as me_me_Lead);
  }

  // Remove UTM from URL
  if (urlUtm && Object.keys(urlUtm).length > 0) {
    const pathname = getPathnameFromAsPath(router.asPath);
    void router.replace(`${pathname}${queryWithoutUtm ? `?${queryWithoutUtm}` : ''}`);
  }
}
